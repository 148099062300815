var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "v-card",
        [
          _c(
            "v-card",
            { attrs: { flat: "" } },
            [
              _c(
                "v-card-text",
                [
                  _c(
                    "v-col",
                    { attrs: { md: "12" } },
                    [
                      _vm._v(
                        " Hier können die Empfänger Konfiguriert werden, welche in verschiedenen Verarbeitungen angeschrieben werden. Erlaubt sind bis zu 3 Empfänger. Werden mehr Empfänger benötigt, lassen Sie von der IT-Hotline einen entsprechenden E-Mail-Verteiler anlegen, der von Extern adressierbar ist. "
                      ),
                      _c("v-data-table", {
                        staticClass: "elevation-1",
                        attrs: {
                          headers: _vm.Headers,
                          items:
                            _vm.$store.state.recipientgroups
                              .recipientgroupsArray,
                          "group-by": "category",
                          "show-group-by": "",
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "top",
                              fn: function () {
                                return [
                                  _c(
                                    "v-toolbar",
                                    { attrs: { flat: "" } },
                                    [
                                      _c("v-spacer"),
                                      _c(
                                        "v-dialog",
                                        {
                                          attrs: { "max-width": "500px" },
                                          scopedSlots: _vm._u([
                                            {
                                              key: "activator",
                                              fn: function ({ on, attrs }) {
                                                return [
                                                  _c(
                                                    "v-btn",
                                                    _vm._g(
                                                      _vm._b(
                                                        {
                                                          staticClass: "mb-2",
                                                          attrs: {
                                                            color: "primary",
                                                            dark: "",
                                                          },
                                                        },
                                                        "v-btn",
                                                        attrs,
                                                        false
                                                      ),
                                                      on
                                                    ),
                                                    [
                                                      _vm._v(
                                                        " Neue Empfängerliste "
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ]),
                                          model: {
                                            value: _vm.dialog,
                                            callback: function ($$v) {
                                              _vm.dialog = $$v
                                            },
                                            expression: "dialog",
                                          },
                                        },
                                        [
                                          _c(
                                            "v-card",
                                            [
                                              _c(
                                                "v-form",
                                                { ref: "form" },
                                                [
                                                  _c("v-card-title", [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass: "text-h5",
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(_vm.formTitle)
                                                        ),
                                                      ]
                                                    ),
                                                  ]),
                                                  _c(
                                                    "v-card-text",
                                                    [
                                                      _c(
                                                        "v-container",
                                                        [
                                                          _c(
                                                            "v-row",
                                                            [
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "6",
                                                                    md: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Kategorie",
                                                                        rules:
                                                                          _vm.categoryRules,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .editedItem
                                                                            .category,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.editedItem,
                                                                              "category",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "editedItem.category",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "6",
                                                                    md: "6",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Name",
                                                                        rules:
                                                                          _vm.nameRules,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .editedItem
                                                                            .shortname,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.editedItem,
                                                                              "shortname",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "editedItem.shortname",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "12",
                                                                    md: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Beschreibung",
                                                                        rules:
                                                                          _vm.descriptionRules,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .editedItem
                                                                            .description,
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm.editedItem,
                                                                              "description",
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "editedItem.description",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "12",
                                                                    md: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Empfänger1",
                                                                        rules:
                                                                          _vm.emailRules,
                                                                        required:
                                                                          "",
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .editedItem
                                                                            .members[0],
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .editedItem
                                                                                .members,
                                                                              0,
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "editedItem.members[0]",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "12",
                                                                    md: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Empfänger2",
                                                                        rules:
                                                                          _vm.emailRules,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .editedItem
                                                                            .members[1],
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .editedItem
                                                                                .members,
                                                                              1,
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "editedItem.members[1]",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "v-col",
                                                                {
                                                                  attrs: {
                                                                    cols: "12",
                                                                    sm: "12",
                                                                    md: "12",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "v-text-field",
                                                                    {
                                                                      attrs: {
                                                                        label:
                                                                          "Empfänger3",
                                                                        rules:
                                                                          _vm.emailRules,
                                                                      },
                                                                      model: {
                                                                        value:
                                                                          _vm
                                                                            .editedItem
                                                                            .members[2],
                                                                        callback:
                                                                          function (
                                                                            $$v
                                                                          ) {
                                                                            _vm.$set(
                                                                              _vm
                                                                                .editedItem
                                                                                .members,
                                                                              2,
                                                                              $$v
                                                                            )
                                                                          },
                                                                        expression:
                                                                          "editedItem.members[2]",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "v-card-actions",
                                                    [
                                                      _c("v-spacer"),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color:
                                                              "blue darken-1",
                                                            text: "",
                                                          },
                                                          on: {
                                                            click: _vm.close,
                                                          },
                                                        },
                                                        [_vm._v(" Cancel ")]
                                                      ),
                                                      _c(
                                                        "v-btn",
                                                        {
                                                          attrs: {
                                                            color:
                                                              "blue darken-1",
                                                            text: "",
                                                          },
                                                          on: {
                                                            click: _vm.save,
                                                          },
                                                        },
                                                        [_vm._v(" Save ")]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                            _vm._l(
                              _vm.Headers.filter((header) =>
                                header.hasOwnProperty("formatter")
                              ),
                              function (header) {
                                return {
                                  key: `item.${header.value}`,
                                  fn: function ({ value }) {
                                    return [
                                      _vm._v(
                                        " " +
                                          _vm._s(header.formatter(value)) +
                                          " "
                                      ),
                                    ]
                                  },
                                }
                              }
                            ),
                            {
                              key: "item.actions",
                              fn: function ({ item }) {
                                return [
                                  _c(
                                    "v-icon",
                                    {
                                      staticClass: "mr-2",
                                      attrs: { small: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.editItem(item)
                                        },
                                      },
                                    },
                                    [_vm._v(" mdi-pencil ")]
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          true
                        ),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }