<template>
    <div>
        <v-card>
            <v-card flat>
                <v-card-text>
                    <v-col md="12">
                        Hier können die Empfänger Konfiguriert werden, welche in verschiedenen Verarbeitungen angeschrieben
                        werden. Erlaubt sind bis zu 3 Empfänger. Werden mehr Empfänger benötigt, lassen Sie von der
                        IT-Hotline einen
                        entsprechenden E-Mail-Verteiler anlegen, der von Extern adressierbar ist.
                        <v-data-table :headers="Headers" :items="$store.state.recipientgroups.recipientgroupsArray" group-by="category" show-group-by
                            class="elevation-1">
                            <template v-slot:top>
                                <v-toolbar flat>
                                    <v-spacer></v-spacer>
                                    <v-dialog v-model="dialog" max-width="500px">
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-btn color="primary" dark class="mb-2" v-bind="attrs" v-on="on">
                                                Neue Empfängerliste
                                            </v-btn>
                                        </template>
                                        <v-card>
                                            <v-form ref="form">
                                                <v-card-title>
                                                    <span class="text-h5">{{ formTitle }}</span>
                                                </v-card-title>

                                                <v-card-text>
                                                    <v-container>

                                                        <v-row>
                                                            <v-col cols="12" sm="6" md="6">
                                                                <v-text-field v-model="editedItem.category"
                                                                    label="Kategorie" :rules="categoryRules"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" sm="6" md="6">
                                                                <v-text-field v-model="editedItem.shortname" label="Name"
                                                                    :rules="nameRules"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" sm="12" md="12">
                                                                <v-text-field v-model="editedItem.description"
                                                                    label="Beschreibung"
                                                                    :rules="descriptionRules"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" sm="12" md="12">
                                                                <v-text-field v-model="editedItem.members[0]"
                                                                    label="Empfänger1" :rules="emailRules"
                                                                    required></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" sm="12" md="12">
                                                                <v-text-field v-model="editedItem.members[1]"
                                                                    label="Empfänger2" :rules="emailRules"></v-text-field>
                                                            </v-col>
                                                            <v-col cols="12" sm="12" md="12">
                                                                <v-text-field v-model="editedItem.members[2]"
                                                                    label="Empfänger3" :rules="emailRules"></v-text-field>
                                                            </v-col>
                                                        </v-row>

                                                    </v-container>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-spacer></v-spacer>
                                                    <v-btn color="blue darken-1" text @click="close">
                                                        Cancel
                                                    </v-btn>
                                                    <v-btn color="blue darken-1" text @click="save">
                                                        Save
                                                    </v-btn>
                                                </v-card-actions>
                                            </v-form>
                                        </v-card>
                                    </v-dialog>
                                </v-toolbar>
                            </template>

                            <template v-for="header in Headers.filter((header) => header.hasOwnProperty('formatter'))"
                                v-slot:[`item.${header.value}`]="{ value }">
                                {{ header.formatter(value) }}
                            </template>
                            <template v-slot:item.actions="{ item }">
                                <v-icon small class="mr-2" @click="editItem(item)">
                                    mdi-pencil
                                </v-icon>
                            </template>
                        </v-data-table>

                    </v-col>
                </v-card-text>
            </v-card>
        </v-card>
    </div>
</template>

<script>

export default {
    data() {
        return {
            Headers: [
                { text: "Kategorie", value: "category" },
                { text: "Name", value: "shortname", sortable: false, groupable: false, },
                { text: "erstellt", value: "createdAt", sortable: false, formatter: this.getDateFromTS,groupable: false, },
                { text: "aktualisiert", value: "updatedAt", sortable: false, formatter: this.getDateFromTS,groupable: false, },
                { text: "Beschreibung", value: "description", sortable: false, groupable: false, },
                { text: "Empfänger", value: "members", sortable: false },
                { text: "Actions", value: "actions", sortable: false, groupable: false, }
            ],
            dialog: false,
            editedIndex: -1,
            editedItem: {
                category: "",
                id: "",
                shortname: "",
                description: "",
                members: [],
            },
            defaultItem: {
                category: "",
                id: "",
                shortname: "",
                description: "",
                members: [],
            },

            descriptionRules: [
                v => (v || "").trim().length > 0 || "Bitte geben Sie eine Beschreibung an"
            ],
            nameRules: [
                v => (v || "").trim().length > 0 || "Bitte geben Sie einen Namen an"
            ],
            categoryRules: [
                v => (v || "").trim().length > 0 || "Bitte geben Sie eine Kategorie an"
            ],
            emailRules: [
                (v) =>
                    !v ||
                    /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
                    "E-Mail unvollständig oder inkorrekt"
            ],
        }
    },
    computed: {
        formTitle() {
            return this.editedIndex === -1 ? "Neuer Eintrag" : "Eintrag bearbeiten"
        },
    },
    watch: {
    },
    methods: {
        getDateFromTS(ts) {
            console.log("typeof", typeof (ts))
            let timestamp;
            switch (typeof (ts)) {
                case "object":
                    timestamp = ts.seconds * 1000;
                    break;
                case "string":
                    timestamp = parseInt(ts)
                    break;
                case "undefined":
                    timestamp = Date.now()
                    break;
                default:
                    timestamp = ts
                    break;
            }
            const dateobj = new Date(timestamp)
            return dateobj.toLocaleString("de-DE")
        },
        editItem(item) {
            this.editedIndex = this.$store.state.recipientgroups.recipientgroupsArray.indexOf(item)
            this.editedItem = Object.assign({}, item)
            this.dialog = true
        },
        close() {
            this.dialog = false
            this.$nextTick(() => {
                this.editedItem = Object.assign({}, this.defaultItem)
                this.editedIndex = -1
            })
        },
        save() {
            if (this.$refs.form.validate()) {
                if (this.editedIndex > -1) {
                    Object.assign(this.$store.state.recipientgroups.recipientgroupsArray[this.editedIndex], this.editedItem)
                    this.$store.dispatch("updateRecipientGroup", { groupdata: this.editedItem }).then(() => {
                        console.log("geschrieben")
                    })

                } else {
                    this.$store.state.recipientgroups.recipientgroupsArray.push(this.editedItem)
                    this.$store.dispatch("insertRecipientGroup", { groupdata: this.editedItem }).then(() => {
                        console.log("geschrieben")
                    })
                }
                this.close()
            }
        },
    },
    mounted() {
        // SignIn Check
        if (!this.$store.state.auth.isSignedIn) {
            this.$router.push("/login")
            return
        }
        this.$store
            .dispatch("getRecipientGroups")
            .then(() => {
                console.log("fertisch")
            })
    }
}
</script>

<style></style>